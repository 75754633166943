<script lang="ts" setup>
import { WarningTriangelIcon } from '@cfgtech/icons'
import { CfgPreloader, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

import type { SbIssuesListStoryblok } from '~/types/storyblok'

const { blok } = defineProps<{ blok: SbIssuesListStoryblok }>()

const { fetchIssues, issues, isLoading } = useIssues()

const { error } = await useAsyncData('issues', () => fetchIssues())

const limitedIssues = computed(() =>
  blok.issueCountLimit && issues.value
    ? issues.value.slice(0, Number(blok.issueCountLimit))
    : issues.value,
)
</script>

<template>
  <div class="w-full" data-allow-mismatch>
    <div
      v-if="!error && limitedIssues?.length"
      class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3"
      data-cy="issues-list"
    >
      <IssuesCard
        v-for="issue in limitedIssues"
        :key="issue.id"
        :issue
      />
    </div>

    <div v-else-if="error || (!isLoading && !limitedIssues?.length) " class="flex w-full flex-col items-center justify-center gap-3">
      <div>
        <WarningTriangelIcon class="shrink-0 text-[32px] text-warning" />
      </div>

      <CfgTypography class="text-warning" :size="CfgTypographySizes.h3">
        {{ $t('error.issue.list') }}
      </CfgTypography>
    </div>

    <div v-else class="col-span-full flex w-full items-center justify-center">
      <CfgPreloader class="mt-8 text-brand" />
    </div>
  </div>
</template>
